import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { BodyLarge, BodySmall, Flex, Separator, H6 } from '@beauty/beauty-market-ui';
import { getSpokenLanguages, getSpokenLevels } from '../../../../constants';
import { SpecialistLanguage } from '../../../../types/specialist';

export const Languages = ({ items }: { items: SpecialistLanguage[] }) => {
  const { t } = useTranslation();

  const languageOptions = getSpokenLanguages(t);
  const levelsOptions = getSpokenLevels(t);

  if (isEmpty(items)) return null;

  return (
    <Flex flexDirection="column">
      <H6 mb="16px">{t('organisation.specialists.languages.title')}</H6>
      {items.map((item, idx, arr) => (
        <Fragment key={item.id}>
          <Flex flexDirection="column" p="8px 0">
            <BodySmall>{levelsOptions[item.level].value}</BodySmall>
            <BodyLarge large lowline>
              {languageOptions[item.language].value}
            </BodyLarge>
          </Flex>
          {idx !== arr.length - 1 && <Separator />}
        </Fragment>
      ))}
    </Flex>
  );
};
