import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Flex, H6, Link } from '@beauty/beauty-market-ui';
import { CutBodySmall } from '../../style';

export const About = ({ text = '' }) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  if (isEmpty(text)) return null;

  return (
    <Flex flexDirection="column">
      <H6 mb="16px">{t('organisation.specialists.specialist-1.about.title')}</H6>
      <CutBodySmall title={text} expanded={expanded} mb="16px" height={expanded ? 'auto' : '80px'}>
        {text}
      </CutBodySmall>
      <Link size="s" design="blue" onClick={() => setExpanded(prev => !prev)}>
        {expanded ? t('generalKeys.showLess') : t('generalKeys.showMore')}
      </Link>
    </Flex>
  );
};
