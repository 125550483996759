import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Flex, getMinWidthMediaQuery, H5, Slider, useMediaQuery } from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../../../constants';
import { getTranslation } from '../../../../helpers/utils';
import { AsyncComponent } from '../../../../hoc';
import { BadgeItem } from '../Rating/BadgeItem';

const Badges = ({ items, isLoading }) => {
  const { t } = useTranslation();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const language = getSelectedLanguage();

  if (isEmpty(items)) return null;

  const slides = items.map(badge => (
    <BadgeItem
      key={badge.title?.text}
      avatar={badge.activeIconUrl}
      title={getTranslation(badge.title, language)}
      subtitle={t(`organisation.rating.ratings`, { count: badge.count })}
    />
  ));

  return (
    <AsyncComponent isLoading={isLoading}>
      <Flex flexDirection="column" gap="16px">
        <H5>{t('organisation.rating.badges')}</H5>
        {isDesktop ? slides : <Slider inlineSlider slides={slides} />}
      </Flex>
    </AsyncComponent>
  );
};

export default memo(Badges);
