import styled from 'styled-components';
import { Flex } from '@beauty/beauty-market-ui';
import { zIndex } from '../../../../constants';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: ${zIndex.customBottomSheet};
  background-color: ${({ theme }) => theme.colors.white.standard};
  padding: 0px 24px 24px 24px;
  box-sizing: border-box;
  border-radius: 24px 24px 0px 0px;
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.08),
    0px 2px 24px rgba(0, 0, 0, 0.08);
`;

export const Header = styled(Flex)`
  width: 100%;
  position: relative;
  justify-content: end;
  padding-bottom: 12px;
`;

export const Content = styled(Flex)`
  width: 100%;
  position: relative;
  justify-content: end;
  padding-bottom: 12px;
  border-bottom: ${({ theme }) => ` 1px solid ${theme.colors.grey.light};`};
`;

export const Footer = styled(Flex)`
  justify-content: space-between;
  gap: 16px;
  padding-top: 16px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.grey.light};`};
`;
