import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Flex, H5, Link, Review } from '@beauty/beauty-market-ui';
import { FullImage, Loader } from '../../../../components';
import { getSelectedLanguage } from '../../../../constants';
import { getFullName, getTranslation } from '../../../../helpers/utils';
import { useFullImage } from '../../../../hooks/useFullImage';

const Reviews = ({ items, isLoading, hasMore, onGetMore }) => {
  const { t } = useTranslation();
  const { image, onClose, isOpen, setImage } = useFullImage();

  const language = getSelectedLanguage();

  if (isEmpty(items)) return null;

  return (
    <Flex flexDirection="column" gap="24px">
      <H5>{t('organisation.specialists.reviewsAbout')}</H5>
      {items.map(item => (
        <Review
          isSidebar
          key={item.createdAt}
          text={item.summary}
          rating={item.score}
          photosUrls={item.photo}
          avatarUrl={item.client?.avatarUrl}
          date={moment(item.createdAt).format('DD.MM.YYYY')}
          service={item?.service?.text ? getTranslation(item.service, language) : ''}
          specialist={getFullName(item.specialist)}
          username={getFullName(item.client)}
          setSelectedPhoto={setImage}
        />
      ))}
      {isLoading && <Loader />}
      {hasMore && (
        <Link size="s" design="blue" onClick={onGetMore}>
          {t('organisation.about.showMore')}
        </Link>
      )}
      <FullImage isOpen={isOpen} src={image} onClose={onClose} />
    </Flex>
  );
};

export default memo(Reviews);
