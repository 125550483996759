import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Div, H2, BodySmall, Button } from '@beauty/beauty-market-ui';
import { FormikInput } from '../../../components/functional/formik/formik-input/FormikInput';
import { InvalidVariants, getSelectedLanguage } from '../../../constants';
import { resetPassword } from '../../../helpers/resetPassword';
import { isRtl, textAlign } from '../../../helpers/rtl';
import { RouterUrl } from '../../../routes/routes';
import { EmailField, EmailForm, emailSchema, initialEmailValue } from './ResetPassword.definitions';

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [invalidType, setInvalidType] = useState<InvalidVariants | null>(null);
  const [isLoading, setLoading] = useState(false);

  const language = getSelectedLanguage();
  const align = textAlign(isRtl());

  const onFormSubmit = useCallback(
    async (data: EmailForm) => {
      setLoading(true);
      const response = await resetPassword({ ...data, language });

      if (response.success) {
        setInvalidType(null);
        navigate(RouterUrl.SentEmailNotification);
      } else if (response.statusCode === 404) setInvalidType(InvalidVariants.NotFound);
      setLoading(false);
    },
    [navigate, invalidType],
  );

  const formikContextValue = {
    initialValues: initialEmailValue,
    validationSchema: emailSchema(t),
    onSubmit: onFormSubmit,
    validateOnMount: false,
  };

  return (
    <Div>
      <H2 mb="16px" textAlign={align}>
        {t('registration.reset')}
      </H2>
      <BodySmall mb="32px" textAlign={align}>
        {t('registration.resetHint')}
      </BodySmall>
      <Formik {...formikContextValue}>
        {({ isValid }) => (
          <Form>
            <FormikInput
              autoFocus
              id={EmailField.Email}
              name={EmailField.Email}
              placeholder="Email"
              invalidType={invalidType}
              onInput={() => setInvalidType(null)}
              rtl={isRtl()}
            />
            <Button
              disabled={!isValid || invalidType || isLoading}
              type="submit"
              design="primary"
              mt="24px"
              width="100%"
              size="large"
            >
              {t(`login.continue`)}
            </Button>
          </Form>
        )}
      </Formik>
    </Div>
  );
};

export default ResetPassword;
