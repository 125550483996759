import Axios from 'axios';
import { registerInterceptors } from './register-interceptors';
import { simpleRequestMethod, complexRequestMethod } from './utils/request-definitions';

const baseURL = process.env.REACT_APP_API_URL_TEST || process.env.REACT_APP_API_URL_DEV;
// const baseURL = process.env.REACT_APP_API_URL_LOCAL; // use this const for locally running API server

// const baseURL = 'http://localhost:3000/api';

export const api = Axios.create({
  baseURL,
});

registerInterceptors(api);

export const apiRequest = {
  get: simpleRequestMethod(api, 'get'),
  delete: simpleRequestMethod(api, 'delete'),
  post: complexRequestMethod(api, 'post'),
  put: complexRequestMethod(api, 'put'),
  patch: complexRequestMethod(api, 'patch'),
};

export const { CancelToken } = Axios;
export const isRequestCancelled = Axios.isCancel;
