import { TFunction } from 'react-i18next';
import range from 'lodash/range';
import { apiRequest } from '../api/api';
import { ReviewEndpoints } from '../api/endpoints/endpoints';
import { AppointmentReviewPayload } from '../types/review';
import { toFormData } from './utils';

export const sendAppointmentReview = async (data: AppointmentReviewPayload) => {
  const [error, response] = await apiRequest.post({
    endpoint: ReviewEndpoints.Appointment,
    data: toFormData(data),
  });
  return response ? response.data : error?.data;
};

export const nullBadges = (count: number) =>
  range(0, count).reduce((state, cur) => {
    state[cur] = false;
    return state;
  }, {});

export const getButtonLabel: (step: number, secondsLeft: number, t: TFunction<'translation', undefined>) => string = (
  step,
  secondsLeft,
  t,
) => {
  if (step < 3) return t('button.next');
  if (step === 3) return t('button.sendReview');
  return `${t(`button.close`)} ${secondsLeft ? `${` 00:`}${`0${secondsLeft}`.slice(-2)}` : ''}`;
};
