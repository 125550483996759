import { useCallback } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { H1, H2, colors, getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';
import Book1x from '../../../assets/orderSection/book@1x.png';
import Book2x from '../../../assets/orderSection/book@2x.png';
import Book3x from '../../../assets/orderSection/book@3x.png';
import Location1x from '../../../assets/orderSection/location@1x.png';
import Location2x from '../../../assets/orderSection/location@2x.png';
import Location3x from '../../../assets/orderSection/location@3x.png';
import Rated1x from '../../../assets/orderSection/rated@1x.png';
import Rated2x from '../../../assets/orderSection/rated@2x.png';
import Rated3x from '../../../assets/orderSection/rated@3x.png';
import { ColumnFlex, CardsWrapper, OrderSectionWrapper } from '../style';
import { BenefitCard } from './BenefitCard/BenefitCard';

type CardProps = {
  title: string;
  description: string;
  imgUrl: string[];
  starsCount?: number;
};

const benefitCards = (t: TFunction<'translation', undefined>): CardProps[] => [
  {
    title: t('order.cards.location.title'),
    description: t('order.cards.location.description'),
    imgUrl: [Location1x, Location2x, Location3x],
  },
  {
    title: t('order.cards.rated.title'),
    description: t('order.cards.rated.description'),
    imgUrl: [Rated1x, Rated2x, Rated3x],
    starsCount: 5,
  },
  {
    title: t('order.cards.book.title'),
    description: t('order.cards.book.description'),
    imgUrl: [Book1x, Book2x, Book3x],
  },
];

export const OrderSection = () => {
  const { t } = useTranslation();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);

  const setImgUrl = useCallback(
    (imgUrls: string[]) => {
      const dpr = Math.round(window.devicePixelRatio);
      return imgUrls.length > dpr ? imgUrls[dpr - 1] : imgUrls[imgUrls.length - 1];
    },
    [window.devicePixelRatio],
  );

  return (
    <OrderSectionWrapper mb="36px">
      <ColumnFlex gap="16px">
        {isMobile ? (
          <H1 textAlign="center" color={colors.white.standard}>
            {t('order.title')}
          </H1>
        ) : (
          <H2 textAlign="center" color={colors.white.standard}>
            {t('order.title')}
          </H2>
        )}

        {/* <H7 textAlign="center">{t('order.description')}</H7> */}
      </ColumnFlex>
      <CardsWrapper>
        {benefitCards(t).map((card, i) => (
          <BenefitCard
            key={`${card.title}`}
            title={card.title}
            description={card.description}
            imgUrl={setImgUrl(card.imgUrl as string[])}
            starsCount={card.starsCount}
            isRight={i === 2}
          />
        ))}
      </CardsWrapper>
    </OrderSectionWrapper>
  );
};
