import React from 'react';
import { Flex, BodySmall, colors, AvatarSizesKey, Avatar, Caption } from '@beauty/beauty-market-ui';
import { BadgeItemContainer } from '../../style';

export type ClientCardProps = {
  title: string;
  subtitle: string;
  avatar?: string | null | File;
  avatarSize?: AvatarSizesKey;
};

export const BadgeItem = ({ title, subtitle, avatar, avatarSize = 'xs' }: ClientCardProps) => {
  const photo = avatar !== undefined && <Avatar size={avatarSize} url={avatar || undefined} />;

  return (
    <BadgeItemContainer>
      {photo}

      <Flex flexDirection="column" gap="2px" pr="8px" mr="auto" width="max-content">
        <BodySmall lowline>{title}</BodySmall>
        <Caption lowline color={colors.grey.dark}>
          {subtitle}
        </Caption>
      </Flex>
    </BadgeItemContainer>
  );
};
