import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import _, { range } from 'lodash';
import moment from 'moment';
import {
  Flex,
  H5,
  H3,
  Loader,
  DateSlider,
  SpecialistBookingCard,
  getMinWidthMediaQuery,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { getSelectedLanguage, INITIAL_DAYS_LOADED, NEXT_DAYS_LOADED } from '../../constants';
import { fetchAvailableTimeslots } from '../../helpers/organisation';
import { getStartDate, getTranslation, scrollTo } from '../../helpers/utils';
import { modifyTimeslotsWithId } from '../../hooks/helpers';
import { useGetServiceInfo } from '../../hooks/useGetServiceInfo';
import { useGetTimeslots } from '../../hooks/useGetTimeslots';
import { OrganisationWorkTime } from '../../page/Organisation/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectUser, updateBooking } from '../../store/redux-slices/userSlice';
import { TextWithTranslations, TimeslotsType } from '../../types';
import { SpecialistsRoles, SpecialistType } from '../../types/specialist';
import MissingTimeslots from './components/MissingTimeslots';
import TimeslotsDay from './components/TimeslotsDay';
import { CardsWrapper, DateSliderWrapper, DaysContainer, StyledElement, Wrapper } from './style';

interface TimeslotsProps {
  orgTimezone: string;
  isAlertVisible: boolean;
  workSchedule: OrganisationWorkTime[];
  selectedDay?: string;
}

interface AvailableTimeslotsProps {
  timestamp: string;
  timeslots?: TimeslotsType;
}

const daysContainerId = 'daysContainer';
const maxCalendarDays = 94;

const currentDate = new Date();

const getAvailableTimeslotsLength = ({ timestamp, timeslots }: AvailableTimeslotsProps) =>
  timeslots && timeslots[timestamp]?.length;

const Timeslots = ({ orgTimezone, selectedDay, isAlertVisible, workSchedule }: TimeslotsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);

  const { booking } = useAppSelector(selectUser);
  const [isLoading, setLoading] = useState(false);
  const selectedLanguage = getSelectedLanguage();

  const { service, specialist, timeslot } = useAppSelector(selectUser).booking;

  const { serviceInfo } = useGetServiceInfo(service?.id);

  const team = serviceInfo?.orgSpecialist;

  const { timeslots, isLoading: initialLoading } = useGetTimeslots(
    INITIAL_DAYS_LOADED,
    maxCalendarDays,
    orgTimezone,
    isAlertVisible,
    service?.id,
    specialist?.id,
  );

  const [shouldDisplayTimeslots, setShouldDisplayTimeslots] = useState(false);
  const [availableTimeslots, setAvailableTimeslots] = useState(timeslots ?? {});
  const [visibleDate, setVisibleDate] = useState<string | undefined>(undefined);
  const [prevDate, setPrevDate] = useState<string | undefined>(undefined);
  const [selectedDate, setSelectedDate] = useState<Date | string | undefined>(undefined);
  const [activeIndex, setActiveIndex] = useState(Object.keys(availableTimeslots)[0]);
  const [chosenDate, setChosenDate] = useState<Date | string | undefined>(undefined);
  const [isDescent, setIsDescent] = useState(false);

  const daysContainerRef = useRef<Element | null>(null);
  const specialistsRef = useRef<Element>();
  const specialistsContainerId = 'specialistsContainer';

  const allTimeslots = useMemo(() => Object.values(availableTimeslots)?.flat(), [availableTimeslots, specialist]);

  const handleLoadTimeslots = async (isDesc: boolean, checkedDate?: string) => {
    if (Object.values(availableTimeslots).findIndex(slot => slot === null) > -1) {
      const newDate = new Date(
        new Date().setDate(currentDate.getDate() + Object.values(availableTimeslots).findIndex(slot => slot === null)),
      );
      newDate.setUTCHours(0, 0, 0, 0);

      setLoading(true);
      let isMoreFetch = true;
      const start = getStartDate(availableTimeslots, checkedDate as string, isDesc);
      let startDate = start ? new Date(start as unknown as Date) : null;
      startDate?.setUTCHours(0, 0, 0, 0);

      let currentSlots = {} as TimeslotsType;

      while (
        isMoreFetch &&
        startDate &&
        (isDesc
          ? moment(startDate).isAfter(moment())
          : moment(startDate).isBefore(moment().add(maxCalendarDays, 'days')) || checkedDate)
      ) {
        // eslint-disable-next-line no-await-in-loop
        const response = await fetchAvailableTimeslots(service!.id, {
          date: moment(startDate).toDate().toISOString(),
          days: NEXT_DAYS_LOADED,
          timezone: orgTimezone,
          orgSpecId: specialist?.id,
        });

        if (response) {
          const extraTimeslots = modifyTimeslotsWithId(response, currentSlots);
          currentSlots = Object.fromEntries(
            Object.entries(availableTimeslots).map(([date, slots]) => {
              const extraSlots = Object.entries(extraTimeslots)?.find(extra => extra[0] === date);
              return [date, (extraSlots && extraSlots[1]) ?? slots];
            }),
          );
          setAvailableTimeslots(currentSlots);
          if (Object.entries(extraTimeslots)?.findIndex(([date, slot]) => slot && slot.length) > -1)
            isMoreFetch = false;
          else
            startDate = moment(startDate)
              .add(isDescent ? -INITIAL_DAYS_LOADED : INITIAL_DAYS_LOADED)
              .toDate();
        }
      }
      setLoading(false);
      return currentSlots;
    }
    return false;
  };

  const sliderDates = useMemo(
    () =>
      range(0, maxCalendarDays).map((day, index) => {
        const currentDay = moment().add(index, 'days');
        const timestamp = currentDay.format('YYYY-MM-DD');
        return {
          timestamp,
          disabled: workSchedule
            ? availableTimeslots[timestamp]?.length === 0 ||
              (!availableTimeslots[timestamp] && workSchedule[Number(currentDay.format('E')) - 1].isWeekend)
            : !availableTimeslots[timestamp] || availableTimeslots[timestamp]?.length === 0,
        };
      }),
    [workSchedule, availableTimeslots, specialist],
  );

  const specialists = useMemo(() => {
    const options: SpecialistType[] = [
      {
        id: '0',
        name: t('organisation.booking.allSpecialists'),
        specialization: serviceInfo?.category
          ? getTranslation(serviceInfo.category as TextWithTranslations, selectedLanguage)
          : '',
        selected: !specialist,
        avatarUrl: '',
        rating: null,
      },
    ];
    team?.forEach(item => {
      item.role !== SpecialistsRoles.MANAGER &&
        item.published &&
        options.push({
          id: item.id,
          name: `${item.name} ${item.surname ?? ''}`,
          specialization: item.specialization || 'Barber',
          avatarUrl: item.avatarUrl,
          rating: null,
        });
    });
    return options;
  }, [team, specialist, t]);

  const hasAvailableTimeslots = useMemo(
    () =>
      !!selectedDate &&
      Boolean(getAvailableTimeslotsLength({ timestamp: selectedDate as string, timeslots: availableTimeslots })),
    [selectedDate, availableTimeslots],
  );

  const onServiceBook = useCallback(
    event => {
      const div = event.target.closest('div');
      if (div.id) {
        const checkedTimeslot = allTimeslots?.find(slot => slot?.id === div.id) || null;
        if (checkedTimeslot) {
          const timeslotWithDate = { ...checkedTimeslot, date: div.dataset.timeslotDate };
          const data = { timeslot: timeslotWithDate };
          dispatch(updateBooking(data));
        }
      }
    },
    [dispatch, allTimeslots],
  );

  const handleOptions = specId => {
    dispatch(
      updateBooking({
        specialist: team?.find(item => item.id === specId),
        timeslot: null,
      }),
    );
  };

  const scrollToSelected = (selected: string, isDayOff: boolean) => {
    setPrevDate(visibleDate);
    setVisibleDate(selected);
    !isDayOff && scrollTo(`day_${selected}`, daysContainerId);
  };

  const handleCalendarClick = useCallback(
    async (timestamp: string) => {
      let hasTimeslots = getAvailableTimeslotsLength({ timestamp, timeslots: availableTimeslots });
      if (!hasTimeslots) {
        if (hasTimeslots === undefined) {
          const newSlots = await handleLoadTimeslots(
            false,
            chosenDate
              ? moment(timestamp)
                  .subtract(INITIAL_DAYS_LOADED - 1, 'days')
                  .format('YYYY-MM-DD')
              : undefined,
          );

          hasTimeslots = getAvailableTimeslotsLength({ timestamp, timeslots: newSlots as TimeslotsType });
          setShouldDisplayTimeslots(!!hasTimeslots);
        }
        setTimeout(() => {
          scrollToSelected(timestamp, !hasTimeslots);
        }, 300);
      } else {
        setShouldDisplayTimeslots(true);
        scrollToSelected(timestamp, false);
      }
      setChosenDate(undefined);
    },
    [availableTimeslots, chosenDate],
  );

  const getSpecialistCard = (spec: SpecialistType) => {
    const { id, avatarUrl, name, specialization } = spec;
    return (
      <SpecialistBookingCard
        key={id}
        avatarUrl={avatarUrl}
        title={name}
        label={specialization}
        checked={id === specialist?.id || (id === '0' && !specialist)}
        onClick={() => handleOptions(id)}
      />
    );
  };

  const getTimeslots = useCallback(
    () =>
      Object.entries(availableTimeslots)?.map(
        ([date, curTimeslots]) =>
          curTimeslots &&
          curTimeslots.length > 0 && (
            <StyledElement data-element-identifier={date} name={`day_${date}`} key={date}>
              <TimeslotsDay
                day={date}
                timeslots={curTimeslots}
                visibleItems={visibleDate}
                setIsVisible={setVisibleDate}
              />
            </StyledElement>
          ),
      ),
    [availableTimeslots, visibleDate],
  );

  const specialistCards = useMemo(
    () => (
      <CardsWrapper id={specialistsContainerId} ref={specialistsRef}>
        {specialists.map(spec =>
          !isMobile ? (
            getSpecialistCard(spec)
          ) : (
            <Element data-element-identifier={spec.id} name={`spec_${spec.id}`} key={spec.id}>
              {getSpecialistCard(spec)}
            </Element>
          ),
        )}
      </CardsWrapper>
    ),
    [specialists, specialist],
  );

  useEffect(() => {
    setIsDescent(moment(prevDate).isAfter(moment(visibleDate)));
  }, [prevDate, visibleDate]);

  useEffect(() => {
    timeslots && setAvailableTimeslots(timeslots);
    const firstAvailableSlots = timeslots && Object.entries(timeslots)?.find(([date, slots]) => slots && slots.length);
    firstAvailableSlots && setSelectedDate(firstAvailableSlots[0]);
  }, [timeslots, specialist]);

  useEffect(() => {
    setShouldDisplayTimeslots(hasAvailableTimeslots);
    hasAvailableTimeslots && chosenDate && handleCalendarClick(chosenDate.toString());
  }, [hasAvailableTimeslots, chosenDate]);

  useEffect(() => {
    timeslot?.id && scrollTo(`timeslot-${timeslot?.id}`, daysContainerId);
    if (timeslot?.id) {
      const daysSlots = Object.entries(availableTimeslots);
      const clickedDate =
        daysSlots && daysSlots.find(([day, slots]) => slots?.find(slot => slot && slot?.id === timeslot?.id));
      clickedDate && setSelectedDate(clickedDate[0]);
    }
  }, [timeslot?.id]);

  useEffect(() => {
    selectedDay && scrollTo(`day_${selectedDay}`, daysContainerId);
  }, [selectedDay]);

  useEffect(() => {
    if (visibleDate) {
      setPrevDate(selectedDate?.toString());
      setSelectedDate(visibleDate);
    }
  }, [visibleDate]);

  useEffect(() => {
    const lessDate = moment(visibleDate).subtract(INITIAL_DAYS_LOADED - 1, 'days');
    !isDescent &&
      !availableTimeslots[moment(visibleDate).add(INITIAL_DAYS_LOADED, 'days').format('YYYY-MM-DD')] &&
      handleLoadTimeslots(false, moment(visibleDate).format('YYYY-MM-DD'));
    isDescent &&
      lessDate.isAfter(moment()) &&
      !availableTimeslots[lessDate.format('YYYY-MM-DD')] &&
      handleLoadTimeslots(true, moment(visibleDate as string).format('YYYY-MM-DD'));
  }, [visibleDate, isDescent]);

  useEffect(() => {
    if (
      !availableTimeslots[moment(selectedDate).format('YYYY-MM-DD')] ||
      (!availableTimeslots[moment(chosenDate).format('YYYY-MM-DD')] && chosenDate)
    ) {
      if (chosenDate) {
        setLoading(true);
        handleCalendarClick(chosenDate.toString());
      }
    } else {
      setLoading(false);
      selectedDate && scrollTo(`day_${selectedDate}`, daysContainerId);
    }
  }, [chosenDate, selectedDate]);

  return (
    <>
      {sliderDates?.length > 0 && (
        <DateSliderWrapper>
          <DateSlider
            key={selectedLanguage}
            dates={sliderDates}
            userLocale={selectedLanguage.toLowerCase()}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setChosenDate={setChosenDate}
            title={<H3>{t('organisation.booking.dateOfVisit')}</H3>}
            todayTitle={t('organisation.booking.today').toUpperCase()}
            titleBottomMargin={24}
            onCalendarCardClick={handleCalendarClick}
            onSlideChange={(slideIndex: string) => setActiveIndex(slideIndex)}
            onNextArrowClick={() => handleLoadTimeslots(false, selectedDate?.toString() as string)}
            width="100%"
          />
        </DateSliderWrapper>
      )}
      <Wrapper>
        <Flex width="100%" alignItems="start" flexDirection="column">
          <H5 mt="40px" mb="16px">
            {t('organisation.booking.selectSpecialist')}
          </H5>
          <Flex width="100%">{specialistCards}</Flex>
          {(shouldDisplayTimeslots || initialLoading || isLoading) && (
            <H5 mt={['20px', '20px', '20px', '40px']} mb="16px">
              {t('organisation.booking.timeslot')}
            </H5>
          )}
        </Flex>

        {(!isLoading || !chosenDate) && !initialLoading && shouldDisplayTimeslots && (
          <DaysContainer
            ref={daysContainerRef}
            id={daysContainerId}
            onClick={onServiceBook}
            selectedId={booking.timeslot?.id}
          >
            {getTimeslots()}
          </DaysContainer>
        )}
        {!isLoading && !initialLoading && !shouldDisplayTimeslots && (
          <MissingTimeslots paddingTop={isMobile ? '60px' : '16px'} />
        )}
        {(isLoading || initialLoading) && <Loader />}
      </Wrapper>
    </>
  );
};

export default Timeslots;
