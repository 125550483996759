import styled from 'styled-components';
import { Button, colors, mediaQueries } from '@beauty/beauty-market-ui';

export const StyledButton = styled(Button)<{ isWhite: boolean }>`
  width: 100%;
  height: 48px;
  background-color: ${({ isWhite }) => (isWhite ? `${colors.white.standard}` : `${colors.black.standard}`)}!important;
  ${mediaQueries.md} {
    width: auto;
  }
`;
