import { useRef } from 'react';
import { Flex, useClickOutside } from '@beauty/beauty-market-ui';
import { FullImageWrapper, FullSizeImage, Overlay } from './style';

export const FullImage = ({ isOpen, src, onClose }) => {
  const ref = useRef(null);

  useClickOutside(ref, onClose);

  return isOpen ? (
    <FullImageWrapper>
      <Overlay />
      <Flex width="100%" justifyContent="center" alignItems="center">
        <FullSizeImage ref={ref} src={src} onClick={onClose} />
      </Flex>
    </FullImageWrapper>
  ) : null;
};
