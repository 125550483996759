import { ReactNode } from 'react';
import { BottomSheet, getMinWidthMediaQuery, Sidebar, useMediaQuery } from '@beauty/beauty-market-ui';

type Props = {
  label: string;
  descriptor: string;
  FooterBody: ReactNode;
  children: ReactNode;
  onClose: () => void;
  isOpen: boolean;
};

export const SidebarSheet = ({ children, onClose, ...rest }: Props) => {
  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);
  return isLarge ? (
    <Sidebar {...rest} onClose={onClose}>
      {children}
    </Sidebar>
  ) : (
    <BottomSheet {...rest} content={children} handleClose={onClose} />
  );
};
