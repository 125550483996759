import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Flex, H5, Link } from '@beauty/beauty-market-ui';
import { FullImage } from '../../../../components';
import { useFullImage } from '../../../../hooks/useFullImage';
import { PortfolioImage, ShowMoreWrapper } from '../../style';

export const Portfolio = ({ items }: { items: string[] }) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  const { isOpen, onClose, setImage, image } = useFullImage();

  const isShowedButton = items.length > 8;

  if (isEmpty(items)) return null;

  return (
    <Flex flexDirection="column" gap="16px">
      <H5>{t('organisation.specialists.portfolio')}</H5>
      <ShowMoreWrapper maxHeight={isExpanded ? 'auto' : '216px'}>
        <Flex gap="8px" flexWrap="wrap">
          {items.map((img, i) => (
            <PortfolioImage key={i} src={img} alt={`img${i}`} onClick={() => setImage(img)} />
          ))}
        </Flex>
      </ShowMoreWrapper>
      {isShowedButton && (
        <Link size="s" design="blue" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? t('organisation.about.showLess') : t('organisation.about.showMore')}
        </Link>
      )}
      <FullImage isOpen={isOpen} onClose={onClose} src={image} />
    </Flex>
  );
};
