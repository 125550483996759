import styled from 'styled-components';

export const TruncatedText = ({ element, width, children, ...rest }) => {
  const Component = styled(element)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: ${width};
  `;

  return <Component {...rest}>{children}</Component>;
};
