import styled from 'styled-components';
import { Flex } from '@beauty/beauty-market-ui';

export const FullImageWrapper = styled(Flex)`
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  z-index: 6;
`;

export const Overlay = styled(Flex)`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  opacity: 0.4;
  background-color: ${({ theme }) => theme.colors.black.standard};
  z-index: 6;
`;

export const FullSizeImage = styled.img`
  position: absolute;
  object-fit: cover;
  z-index: 6;
  opacity: 1;
  border-radius: 10px;

  width: auto;
  max-width: 100vw;
  height: fit-content;
  max-height: calc(100vh - 152px);

  :hover {
    cursor: pointer;
  }
`;
