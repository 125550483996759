import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { Button, Flex, Loader } from '@beauty/beauty-market-ui';
import { MAX_MARK } from '../../constants';
import { getButtonLabel, nullBadges } from '../../helpers/review';
import { useStepper } from '../../hooks/useStepper';
import { useAppDispatch } from '../../store/hooks';
import { setActiveNotificationId, setForceFetch } from '../../store/redux-slices/userSlice';
import { OrgAddressType } from '../../types/organisation';
import { OrgSpecialistType } from '../../types/specialist';
import { SidebarSheet } from '../SidebarSheet';
import { FeedbackForm } from './FeedbackForm';
import { ResultInfo } from './ResultInfo';
import { SidebarBody } from './SidebarBody';
import { ColumnFlex } from './style';

export type RatingSidebarProps = {
  appointmentId: string;
  date: string;
  address: OrgAddressType;
  specialist?: OrgSpecialistType;
  setOpen?: (isOpen: boolean) => void;
};

export const RatingSidebar = ({ appointmentId, date, address, specialist, setOpen }: RatingSidebarProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [stars, setStars] = useState(0);
  const [orgScore, setOrgScore] = useState(0);
  const [specScore, setSpecScore] = useState(0);
  const [valid, setValid] = useState(false);
  const [responseStatus, setResponseStatus] = useState<string | null>(null);
  const [secondsLeft, setSecondsLeft] = useState(10);
  const [isLoading, setLoading] = useState(false);

  const formRef = useRef<FormikProps<{ feedback: string }>>(null);

  const title = useMemo(
    () => ({
      1: t('rating.evaluateSpecialist'),
      2: t('rating.evaluateSalon'),
      3: t('rating.feedback'),
    }),
    [t],
  );

  const {
    step,
    StepperComponent,
    handleReset,
    handleNextStep,
    specialistCards,
    salonCards,
    specBadges,
    setSpecBadges,
    salonBadges,
    setSalonBadges,
    isLoadingCards,
  } = useStepper({
    title,
    count: specialist ? 3 : 2,
    isSpecialist: !!specialist,
  });

  const cards = useMemo(() => (step === 1 ? specialistCards : salonCards), [step, specialistCards, salonCards]);

  const [badges, setBadges] = useState(cards ? nullBadges(cards[stars]?.length) : nullBadges(MAX_MARK));

  const disabled = step <= 2 ? !stars : (stars < 4 && !valid) || false;

  const handleCloseSidebarOrFinishReview = () => {
    dispatch(setActiveNotificationId(null));
    step === 4 && dispatch(setForceFetch(true));
    setOpen && setOpen(false);
    handleReset();
  };

  const handleClick = () => {
    switch (step) {
      case 1:
        setSpecBadges(badges);
        setSpecScore(stars);
        break;
      case 2:
        setSalonBadges(badges);
        setOrgScore(stars);
        break;
      case 3:
        formRef.current?.handleSubmit();
        break;
      case 4:
        handleCloseSidebarOrFinishReview();
        break;
      default:
        setStars(0);
    }
    handleNextStep();
    setStars(0);
  };

  const footer = (
    <Button disabled={disabled} design="primary" width="100%" size="large" onClick={handleClick}>
      {getButtonLabel(step, secondsLeft, t)}
    </Button>
  );

  const generalProps = {
    isOpen: true,
    onClose: () => {
      handleCloseSidebarOrFinishReview();
    },
    label: t('rating.rateAppointment'),
    descriptor: `${date}, ${address.info}`,
    FooterBody: footer,
  };

  const content = (
    <ColumnFlex>
      {step < 4 && StepperComponent}
      {step === 3 && (
        <FeedbackForm
          ref={formRef}
          appointmentId={appointmentId}
          orgScore={orgScore}
          specScore={specScore}
          salonCards={salonCards[orgScore]}
          specialistCards={specialistCards[specScore]}
          salonBadges={salonBadges}
          specBadges={specBadges}
          setLoading={setLoading}
          setSecondsLeft={setSecondsLeft}
          setResponseStatus={setResponseStatus}
          setValid={setValid}
        />
      )}
      {step === 4 &&
        (isLoading ? (
          <Flex alignItems="center" justifyContent="center" mt="50%">
            <Loader />
          </Flex>
        ) : (
          <ResultInfo responseStatus={responseStatus} />
        ))}
      {step < 3 && (
        <SidebarBody
          step={step}
          title={title}
          address={address}
          specialist={specialist}
          stars={stars}
          setStars={setStars}
          cards={cards}
          isLoadingCards={isLoadingCards}
          badges={badges}
          setBadges={setBadges}
        />
      )}
    </ColumnFlex>
  );

  useEffect(() => {
    setBadges(cards ? nullBadges(cards[stars]?.length) : nullBadges(MAX_MARK));
  }, [stars, cards]);

  useEffect(() => {
    if (!secondsLeft) {
      handleCloseSidebarOrFinishReview();
    }
  }, [secondsLeft]);

  return <SidebarSheet {...generalProps}>{content}</SidebarSheet>;
};
