import { useEffect, useState } from 'react';
import { apiRequest } from '../../api/api';
import { ReviewEndpoints } from '../../api/endpoints/endpoints';
import { BadgeType } from '../../types';

export const useGetSpecialistRating = (id?: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<unknown>(null);
  const [badges, setBadges] = useState<BadgeType[]>([]);
  const [rating, setRating] = useState<string>('0.0');
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    let isBadgesLoading = true;

    const fetchSpecialistBadges = async () => {
      setIsLoading(true);
      const [error, response] = await apiRequest.get({
        endpoint: ReviewEndpoints.SpecialistRating,
        endpointParams: id,
      });

      if (isBadgesLoading) {
        if (error?.data) setErrorMessage(error?.data);
        setRating(response?.status === 200 ? response?.data?.rating : '0');
        setBadges(response?.status === 200 ? response?.data?.badges : []);
        setCount(response?.status === 200 ? response?.data?.reviews : 0);
        setIsLoading(false);
      }
    };

    id && fetchSpecialistBadges();

    return () => {
      isBadgesLoading = false;
    };
  }, [id]);

  return {
    isLoading,
    errorMessage,
    badges,
    rating,
    count,
  };
};
