import { StyledButton } from './style';

type ApplicationButtonProps = {
  iconSource: string;
  iconSrc: string;
  design: string;
  altText: string;
  isWhite?: boolean;
};

export const ApplicationButton = ({
  iconSource,
  design,
  altText,
  iconSrc,
  isWhite = false,
}: ApplicationButtonProps) => (
  <StyledButton design={design} isWhite={isWhite}>
    <img src={iconSource} alt={altText} />
    <img src={iconSrc} alt={altText} />
  </StyledButton>
);
