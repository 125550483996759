import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Flex } from '@beauty/beauty-market-ui';
import { Stub } from '../../../../components';
import { AsyncComponent } from '../../../../hoc';
import { useGetSpecialistInformation, useGetSpecialistReview } from '../../../../hooks';
import { useGetSpecialistRating } from '../../../../hooks/specialist/useGetSpecialistRating';
import { About } from './About';
import Badges from './Badges';
import { Education } from './Education';
import { Languages } from './Languges';
import { Portfolio } from './Portfolio';
import Reviews from './Reviews';

export const SpecialistInfo = ({ id, headOrgSpecId }) => {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  const { information, isLoading: isSpecialistLoading } = useGetSpecialistInformation(headOrgSpecId);
  const { reviews, totalPages, isLoading: isReviewsLoading } = useGetSpecialistReview(id, page);
  const { badges, isLoading: isBadgesLoading } = useGetSpecialistRating(id);

  const getMoreReviews = useCallback(() => {
    setPage(prev => prev + 1);
  }, []);

  const isSpecialistEmpty =
    isEmpty(reviews) &&
    isEmpty(information?.about) &&
    isEmpty(information?.spokenLanguage) &&
    isEmpty(information?.education) &&
    isEmpty(information?.photo) &&
    isEmpty(badges);

  const isSpecialistInfoLoading = isSpecialistLoading || isBadgesLoading || isReviewsLoading;

  return (
    <Flex flexDirection="column" gap="40px">
      {isSpecialistEmpty && !isSpecialistInfoLoading && <Stub title={t('generalKeys.noInfo')} margin="50px auto 0" />}
      <AsyncComponent isLoading={isSpecialistLoading}>
        <About text={information?.about ?? ''} />
        <Languages items={information?.spokenLanguage ?? []} />
        <Education items={information?.education ?? []} />
        <Portfolio items={information?.photo.map(item => item.url) ?? []} />
      </AsyncComponent>
      <Badges items={badges} isLoading={isBadgesLoading} />
      <Reviews items={reviews} isLoading={isReviewsLoading} hasMore={page < totalPages} onGetMore={getMoreReviews} />
    </Flex>
  );
};
