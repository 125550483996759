import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { H1, BodySmall, Button, Flex } from '@beauty/beauty-market-ui';
import { setAccessToken } from '../../../api/api.helpers';
import { AccType, InvalidVariants } from '../../../constants';
import { registration, smsForm } from '../../../helpers/registration';
import { isRtl, textAlign } from '../../../helpers/rtl';
import { signupPhoneVerification } from '../../../helpers/signupPhoneVerification';
import { getInvalidType } from '../../../helpers/utils';
import { useGetSecondsLeft } from '../../../hooks/useGetSecondsLeft';
import { RouterUrl } from '../../../routes/routes';
import { useAppDispatch } from '../../../store/hooks';
import { updateSmsCodeTime, updateUser } from '../../../store/redux-slices/userSlice';
import { CreateAccountType } from '../../../types/user';
import { Rules } from '../componets/Rules';
import { initialSmsCodeValues, LoginSmsCodeForm, loginSMSCodeFormValidationSchema } from '../Login.definitions';

const PhoneVerification = () => {
  const { t } = useTranslation();

  const rtl = isRtl();
  const align = textAlign(rtl);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const { agreement, email, name, surname, password, number, code, idNumber } = state;
  const [invalidType, setInvalidType] = useState<InvalidVariants | null>(null);
  const [isDefaultValue, setDefaultValue] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const secondsLeft = useGetSecondsLeft();

  const onFormSubmit = useCallback(
    async (data: LoginSmsCodeForm) => {
      setLoading(true);
      const values: CreateAccountType = {
        name,
        surname,
        code,
        number,
        email,
        password,
        marketingNotes: agreement,
        otp: data.smscode,
        accType: AccType.USER,
        idNumber,
      };

      const response = await registration(values);
      if (response.id) {
        setInvalidType(null);
        const {
          email: userEmail,
          name: userName,
          surname: userSurname,
          id,
          avatarUrl,
          code: userCode,
          number: userNumber,
          idNumber: userIdNumber,
        } = response;
        setAccessToken(response.accessToken);
        dispatch(
          updateUser({
            isLogin: true,
            user: {
              name: userName,
              surname: userSurname,
              email: userEmail,
              code: userCode,
              number: userNumber,
              userId: id,
              avatarUrl,
              idNumber: userIdNumber,
            },
          }),
        );
        if (state.orgId) {
          navigate(`${RouterUrl.Organisation}/${state.orgId}`);
        } else {
          navigate(RouterUrl.Homepage);
        }
      } else setInvalidType(getInvalidType(response.statusCode, 'codeVerification'));
      setLoading(false);
    },
    [agreement, email, name, surname, password, number, code, idNumber, invalidType],
  );

  const handleResendClick = async () => {
    setInvalidType(null);
    setLoading(true);
    const phoneVer = { code, number, email };
    const response = await signupPhoneVerification(phoneVer);
    if (response.statusCode === 201) {
      dispatch(updateSmsCodeTime(new Date().getTime()));
    } else {
      setInvalidType(getInvalidType(response.statusCode, 'codeVerification'));
    }
    setLoading(false);
  };

  const formikContextValue = {
    initialValues: initialSmsCodeValues,
    validationSchema: loginSMSCodeFormValidationSchema(t),
    onSubmit: onFormSubmit,
    validateOnMount: true,
  };

  return (
    <Flex flexDirection="column" alignItems={align}>
      <H1 mb="16px">{t('registration.smsCode')}</H1>
      <BodySmall mb="32px" textAlign={align}>
        {t('registration.smsHint')}
        <br />
        {`${code} ${number}`}
      </BodySmall>
      {smsForm(formikContextValue, invalidType, setInvalidType, isDefaultValue, t, rtl)}
      <Button
        disabled={secondsLeft || isLoading}
        design="quaternary"
        mt="16px"
        width="100%"
        size="large"
        onClick={() => {
          handleResendClick();
          setDefaultValue(true);
        }}
      >
        {`${t(`registration.resendCode`)}${secondsLeft ? `${` 00:`}${`0${secondsLeft}`.slice(-2)}` : ``}`}
      </Button>
      <Rules />
    </Flex>
  );
};

export default PhoneVerification;
