import { Flex, RadioButton } from '@beauty/beauty-market-ui';

type FilterMobileItemProps = {
  id: number | null;
  body: JSX.Element;
  setValue: (value: number | null) => void;
  isChecked?: boolean;
};

export const FilterMobileItem = ({ id, body, setValue, isChecked }: FilterMobileItemProps) => (
  <Flex width="100%" height="40px" justifyContent="space-between" alignItems="center">
    {body}
    <RadioButton checked={isChecked} onClick={() => setValue(id)} />
  </Flex>
);
