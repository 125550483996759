import { useEffect, useState } from 'react';
import { uniqBy } from 'lodash';
import { apiRequest } from '../../api/api';
import { ReviewEndpoints } from '../../api/endpoints/endpoints';
import { Order, SpecialistReviewType } from '../../types';

type ReturnType = {
  isLoading: boolean;
  errorMessage: unknown;
  reviews: SpecialistReviewType[];
  totalPages: number;
};

export const useGetSpecialistReview = (id = '', page = 1, size = 5, order: Order = 'date_asc'): ReturnType => {
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<unknown>(null);
  const [reviews, setReviews] = useState<SpecialistReviewType[]>([]);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchReviews = async () => {
      setLoading(true);
      const [error, response] = await apiRequest.get({
        endpoint: ReviewEndpoints.SpecialistReview,
        endpointParams: { id, queryParams: `?size=${size}&page=${page}&order=${order}` },
      });
      if (error?.data) setErrorMessage(error?.data);
      if (response?.data) {
        setReviews(uniqBy(reviews.concat(response?.data.data), 'createdAt'));
        setTotalPages(response.data.pages);
      }
      setLoading(false);
    };

    id && fetchReviews();
  }, [id, page, size, order]);

  return { reviews, totalPages, isLoading, errorMessage };
};
