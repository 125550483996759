import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BodyLarge, BodySmall, Flex, H4, Icon, RightIcon, Separator } from '@beauty/beauty-market-ui';
import { ChangePasswordSidebar } from '../Sidebars/ChangePasswordSidebar';

export const ChangePassword = () => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  return (
    <Flex flexDirection="column" mb={['56px', '56px', '56px', '80px']}>
      <H4 mb="16px">{t('profile.password.changePassword')}</H4>
      <Flex gap="8px" alignItems="center" cursor="pointer" onClick={() => setOpen(true)} p="12px 0px 13px">
        <Flex flexDirection="column">
          <BodyLarge large>{t('profile.password.updatePassword')}</BodyLarge>
          <BodySmall>{t('profile.password.changePasswordToSecure')}</BodySmall>
        </Flex>
        <Icon width="20px" height="20px" ml="auto">
          <RightIcon />
        </Icon>
      </Flex>
      <Separator />
      <ChangePasswordSidebar isOpen={isOpen} onClose={() => setOpen(false)} />
    </Flex>
  );
};
