import styled, { css } from 'styled-components';
import { colors, mediaQueries, Flex, Div } from '@beauty/beauty-market-ui';
import { zIndex } from '../../constants';

export const HeaderWrapper = styled.header<{ isOnTop: boolean }>`
  ${({ isOnTop }) => css`
    ${isOnTop
      ? `
          z-index: ${zIndex.header};
          background-color: ${colors.white.standard};`
      : `
          z-index: ${zIndex.header};
          background-color: transparent;`}
  `}
  box-sizing: border-box;
  grid-area: header;
  grid-row: row1;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: start;
  height: 72px;
  position: sticky;
  top: 0px;

  ${mediaQueries.md} {
    height: 80px;
    margin: auto;
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (max-width: 1060px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`;

export const HeaderGrid = styled(Div)`
  width: 100%;
  display: grid;
  justify-content: space-between;
  ${({ rtl }) =>
    rtl
      ? css`
          grid-template-columns: 28% 52% 20%;
          grid-template-areas: 'navigationMenu search logo';
        `
      : css`
          grid-template-columns: 20% 52% 28%;
          grid-template-areas: 'logo search navigationMenu';
        `}
  @media screen and (max-width: 900px) {
    grid-template-columns: minmax(25%, 100%);
    grid-template-areas:
      ${({ rtl }) => (rtl ? 'navigationMenu logo' : 'logo navigationMenu')}
      'search search';
  }
`;

export const LogoutButton = styled.button`
  cursor: pointer;
  display: block;
  padding: 0;
  color: ${colors.black.standard};
  border: none;
  background: transparent;
  font-size: 14px;
`;

export const MenuWrapper = styled(Flex)`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 84px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 87.62%);
`;

export const HeaderWrapperNew = styled.header`
  z-index: 6;
  background-color: ${colors.white.standard};

  box-sizing: border-box;
  grid-area: header;
  grid-row: row1;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: start;
  height: 72px;
  position: sticky;
  top: 0px;

  ${mediaQueries.md} {
    height: 80px;
    max-width: 1440px;
    margin: auto;
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (max-width: 1060px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`;

export const HeaderGridNew = styled(Div)`
  width: 100%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 20% 55% 25%;
  grid-template-areas: 'logo search navigationMenu';
  @media screen and (max-width: 900px) {
    grid-template-columns: minmax(25%, 100%);
    grid-template-areas:
      'logo navigationMenu'
      'search search';
  }
`;

export const BurgerWrapper = styled(Flex)`
  button {
    padding: 0 !important;
    vertical-align: middle;
  }
`;

export const BellWrapper = styled(Flex)<{ counter: number }>`
  position: relative;
  :hover {
    cursor: pointer;
  }
`;

export const CaptionWrapper = styled(Flex)`
  border-radius: 16px;
  position: absolute;
  width: fit-content;
  left: 8px;
  top: -8px;
  padding: 1px 5px;
  background-color: ${colors.blue.standard};

  span {
    color: ${colors.white.standard};
  }
`;

export const ButtonsWrapper = styled(Flex)`
  gap: 24px;
  justify-items: end;
  align-items: center;
  width: 100%;
  height: 48px;
  ${({ rtl }) =>
    rtl
      ? css`
          flex-direction: row-reverse;
          justify-content: start;
        `
      : css`
          flex-direction: row;
          justify-content: end;
        `}
`;

export const StyledWrapper = styled(Flex)<{ rtl: boolean }>`
  gap: 24px;
  align-items: center;
  flex-direction: ${({ rtl }) => (rtl ? 'row-reverse' : 'row')};

  * {
    white-space: nowrap;
  }
`;
