import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Slider, BodySmall, colors, Loader, RatingStars } from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../constants';
import { getTranslation } from '../../helpers/utils';
import { OrgAddressType } from '../../types/organisation';
import { BadgeType } from '../../types/review';
import { OrgSpecialistType } from '../../types/specialist';
import { RatingCard } from './RatingCard';
import { ReviewBadge } from './ReviewBadge';
import { ColumnFlex } from './style';

type SidebarBodyProps = {
  step: number;
  title: { [key: string]: string };
  address: OrgAddressType;
  stars: number;
  setStars: Dispatch<SetStateAction<number>>;
  cards: {
    [key: string]: BadgeType[];
  };
  isLoadingCards: boolean;
  badges: { [key: string]: boolean };
  setBadges: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  specialist?: OrgSpecialistType;
};

export const SidebarBody = ({
  step,
  title,
  address,
  stars,
  setStars,
  cards,
  isLoadingCards,
  badges,
  setBadges,
  specialist,
}: SidebarBodyProps) => {
  const { t } = useTranslation();

  const { avatarUrl, name, surname, specialization } = specialist || ({} as OrgSpecialistType);
  const { url: addressUrl, orgName, info } = address;
  const language = getSelectedLanguage();

  const slides = useMemo(
    () =>
      cards
        ? cards[String(stars)]?.map((card, id) => (
            <ReviewBadge
              key={card.title?.text}
              url={card[badges[id] ? 'activeIconUrl' : 'inactiveIconUrl']}
              title={getTranslation(card.title, language)}
              onClick={() => {
                setBadges({ ...badges, [`${id}`]: !badges[id] });
              }}
              isActive={badges[id]}
            />
          ))
        : null,
    [cards, stars, badges],
  );

  return (
    <ColumnFlex mt="40px" alignItems="center">
      <RatingCard
        avatarUrl={step === 1 ? avatarUrl : addressUrl}
        title={step === 1 ? `${name} ${surname}` : orgName}
        info={step === 1 ? specialization : info}
        isChecked={stars > 0}
      />
      <ColumnFlex>
        <RatingStars rating={stars} onClick={setStars} />

        <BodySmall lowline textAlign="center" color={colors.grey.dark} mb="81px">
          {stars ? t('rating.yourRating') : title[step]}
        </BodySmall>
      </ColumnFlex>
      {cards && stars && slides ? (
        <Slider title={stars > 3 ? t('rating.whatLike') : t('rating.whatWrong')} slides={slides} width="100%" />
      ) : (
        (stars && isLoadingCards && <Loader />) || null
      )}
    </ColumnFlex>
  );
};
