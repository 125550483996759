import { useCallback, useState } from 'react';
import { Nullable } from 'tsdef';

export const useFullImage = () => {
  const [image, setImage] = useState<Nullable<string>>(null);

  const onClose = useCallback(() => setImage(null), []);

  return {
    onClose,
    image,
    setImage,
    isOpen: !!image,
  };
};
