import React, { useEffect } from 'react';
// TODO: Fix eslint issue
// eslint-disable-next-line
import ReactGA from 'react-ga';
import { withTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { BMTheme, theme } from '@beauty/beauty-market-ui';
import packageJson from '../package.json';
import Router from './routes/Router';
import { AppWrapper } from './style';

ReactGA.initialize('G-BQ4GX4GDZD');

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const isVersionNotMatch = localStorage.getItem('version') !== packageJson.version;
    if (isVersionNotMatch) {
      localStorage.clear();
      localStorage.setItem('version', packageJson.version);
    }
  }, []);

  return (
    <BMTheme>
      <ThemeProvider {...{ theme }}>
        <AppWrapper>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </AppWrapper>
      </ThemeProvider>
    </BMTheme>
  );
};

export default withTranslation()(App);
