import { useTranslation } from 'react-i18next';
import { H1, H2, Caption, getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';
import AppStore from '../../../assets/app-store.svg';
import AppleSet1x from '../../../assets/apple-set@1x.png';
import AppleSet2x from '../../../assets/apple-set@2x.png';
import AppleSet3x from '../../../assets/apple-set@3x.png';
import Apple from '../../../assets/apple.svg';
import Arrow from '../../../assets/arrow.svg';
import GooglePlay from '../../../assets/google-play.svg';
import { ApplicationButton } from '../../../components/ApplicationButton/ApplicationButton';
import { ColumnFlex, Description, LeftWrapper, MobilePreviewImage, MobilePreviewWrapper, StyledFlex } from '../style';

export const isRetina = (first, second) => {
  if (window.devicePixelRatio >= 2) {
    return second;
  }
  return first;
};

export const MobilePreviewSection = () => {
  const { t } = useTranslation();
  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);
  const title = isMobile ? <H1>{t('home.application')}</H1> : <H2>{t('home.application')}</H2>;

  return (
    <ColumnFlex pt={['156px', '156px', '156px', '240px']} mb={['0', '0', '0', '126px']}>
      <MobilePreviewWrapper>
        <LeftWrapper>
          <Caption large>{t('home.comingSoon')}</Caption>
          {title}
          <Description maxWidth="400px">{t('home.oneSolution')}</Description>
          <StyledFlex gap="8px" pt="16px">
            <ApplicationButton iconSource={Apple} iconSrc={AppStore} design="tertiary" altText="Apple Button" isWhite />
            <ApplicationButton
              iconSource={Arrow}
              iconSrc={GooglePlay}
              design="tertiary"
              altText="Google Button"
              isWhite
            />
          </StyledFlex>
        </LeftWrapper>
        <MobilePreviewImage>
          <img src={AppleSet1x} srcSet={`${AppleSet1x} 1x,${AppleSet2x} 2x,${AppleSet3x} 3x`} alt="" />
          {/* <img src={isRetina(AppleSet1x, AppleSet2x)} alt="" /> */}
        </MobilePreviewImage>
      </MobilePreviewWrapper>
    </ColumnFlex>
  );
};
