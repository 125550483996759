import { useTranslation } from 'react-i18next';
import { H3 } from '@beauty/beauty-market-ui';
import { data } from '../constants';
import { ReviewCard, StyledSlider } from '../style';

export const ReviewsBlock = () => {
  const { t } = useTranslation();
  return (
    <StyledSlider
      title={<H3>{t('home.reviews.title')}</H3>}
      slides={data(t).reviews.map(review => (
        <ReviewCard
          key={review.id}
          avatarUrl={review.avatarUrl}
          username={review.username}
          date={review.date}
          rating={review.rating}
          text={review.text}
          overflowText="ellipsis"
        />
      ))}
    />
  );
};
