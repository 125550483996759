import { useState } from 'react';
import { Stepper } from '@beauty/beauty-market-ui';
import { nullBadges } from '../helpers/review';
import { useGetRatingCards } from './useGetRatingCards';

interface UseStepperProps {
  title: object;
  count: number;
  isSpecialist: boolean;
}

export const useStepper = ({ title, count, isSpecialist }: UseStepperProps) => {
  const [step, setStep] = useState(isSpecialist ? 1 : 2);
  const [specBadges, setSpecBadges] = useState(nullBadges);
  const [salonBadges, setSalonBadges] = useState(nullBadges);

  const handleReset = () => setStep(0);
  const handleNextStep = () => setStep(step + 1);

  const { cards: specialistCards, isLoading: isSpecCardsLoading } = useGetRatingCards('specialist');
  const { cards: salonCards, isLoading: isOrgCardsLoading } = useGetRatingCards('organization');

  const StepperComponent = (
    <Stepper title={title[step]} stepsCount={count} currentStep={isSpecialist ? step : step - 1} />
  );

  return {
    step,
    StepperComponent,
    handleNextStep,
    handleReset,
    specialistCards,
    salonCards,
    specBadges,
    setSpecBadges,
    salonBadges,
    setSalonBadges,
    isLoadingCards: isSpecCardsLoading || isOrgCardsLoading,
  };
};
