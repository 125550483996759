import { useEffect, useState } from 'react';
import { apiRequest } from '../../api/api';
import { SpecialistEndpoints } from '../../api/endpoints/endpoints';
import { SpecialistInformation } from '../../types/specialist';

export const useGetSpecialistInformation = (id?: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [information, setInformation] = useState<SpecialistInformation | null>(null);

  useEffect(() => {
    let isInformationLoading = true;

    const fetchSpecialistInformation = async () => {
      setIsLoading(true);
      const [, response] = await apiRequest.get({
        endpoint: SpecialistEndpoints.PersonalInformation,
        endpointParams: id,
      });

      if (isInformationLoading) {
        setInformation(response?.status === 200 ? response?.data : {});
        setIsLoading(false);
      }
    };

    id && fetchSpecialistInformation();

    return () => {
      isInformationLoading = false;
    };
  }, [id]);

  return {
    isLoading,
    information,
  };
};
