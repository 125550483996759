import { Form } from 'formik';
import styled, { css } from 'styled-components';
import { Flex, BodySmall, colors, BodyLarge, Link, Button, mediaQueries } from '@beauty/beauty-market-ui';
import LogImage from '../../assets/log-image.png';
import { zIndex } from '../../constants';
import { textAlign } from '../../helpers/rtl';

export const LoginWrapper = styled(Flex)`
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1060px;
  box-sizing: border-box;
  height: 100%;
  margin-bottom: 26px;

  ${mediaQueries.md} {
    justify-content: start;
    margin-bottom: 0;
  }

  @media (max-width: 1060px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media (min-width: 1441px) {
    position: relative;
  }
`;

export const InfoWrapper = styled(Flex)`
  flex-direction: column;
  width: fit-content;
  min-height: fit-content;
  z-index: ${zIndex.loginInfoWrapper};
  margin-top: 104px;

  ${mediaQueries.md} {
    margin-bottom: 0;
    margin-top: 0;
    justify-content: center;
  }
`;

export const ImageBlock = styled.div`
  background: url(${LogImage}) no-repeat center top -80px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  right: 0;
  width: 810px;
  height: 1037px;
  margin-left: 40px;

  @media (min-width: 1441px) {
    left: 400px;
  }

  @media (max-width: 1000px) {
    display: none !important;
  }
`;

export const SubtextStyled = styled(BodySmall)`
  color: ${colors.grey.dark};
`;

export const TextStyled = styled(BodyLarge)`
  color: ${colors.blue.standard};
  text-align: center;
  cursor: pointer;
  margin-bottom: 46px;
`;

export const IconWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  padding: 8px 0;
  gap: 8px;
  background-color: #f2f2f2;
  border-radius: 100%;

  svg {
    width: 24px;
    height: 24px;
  }

  :hover {
    border-radius: 10px;
    border: 1px solid ${colors.grey.standard};
    padding: 7px 0;

    & span {
      color: ${colors.grey.dark};
    }
  }
`;

export const WelcomWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  margin-top: 195px;
`;

export const LinkStyled = styled(Link)`
  display: inline !important;
`;

export const LinkWrapper = styled(Link)`
  margin-top: 16px;
  margin-bottom: 32px;
  justify-content: center;
`;

export const StyledFlex = styled(Flex)`
  input {
    width: 100%;
  }
`;

export const ButtonWrapper = styled(Flex)<{ isClient: boolean }>`
  flex-direction: column;
  align-items: start;
  gap: 8px;
  ${({ isClient }) => css`
    & * {
      color: ${isClient ? colors.white.standard : colors.black.standard}!important;
    }
  `}
`;

export const StyledButton = styled(Button)`
  border-radius: 16px !important;
  padding: 24px !important;
  & {
    justify-content: space-between !important;
  }
`;

export const LoginInfoWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  min-height: fit-content;
  z-index: ${zIndex.loginInfoWrapper};

  ${mediaQueries.md} {
    margin-bottom: 0;
  }
`;

export const LoginChoiceWrapper = styled(Flex)`
  justify-content: space-between;
  margin-left: 36px;
  margin-right: 36px;
  padding-top: 24px;
  padding-bottom: 24px;
  ${mediaQueries.md} {
    margin-left: 96px;
    margin-right: 96px;
    padding-top: 32px;
    padding-bottom: 32px;
  }
`;

export const TabsWrapper = styled(Flex)<{ rtl: boolean }>`
  width: 100%;
  align-items: ${({ rtl }) => textAlign(rtl)};
  & > div {
    width: 100%;
    form > div > div:nth-child(2) > div {
      width: 100%;
    }
  }
`;

export const StyledForm = styled(Form)`
  width: 100%;
`;

export const SmsFormWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  ${({ rtl }) => `
  & > form > div {
    display: flex;
    justify-content: ${rtl ? 'end' : 'start'};`}
  }
`;
