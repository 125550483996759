import { useTranslation } from 'react-i18next';
import { Div, H6, BodySmall, Button, LoginIcon, colors } from '@beauty/beauty-market-ui';
import { AccountLogInWrapper } from '../style';

type AccountLogInProps = {
  onSignInClick: () => void;
};
const AccountLogIn = ({ onSignInClick }: AccountLogInProps) => {
  const { t } = useTranslation();
  return (
    <AccountLogInWrapper mt="16px">
      <Div>
        <H6 mb="4px" color={colors.blue.standard}>
          {t('login.logInto')}
        </H6>
        <BodySmall>{t('login.continueAsGuest')}</BodySmall>
      </Div>
      <Button prefix={<LoginIcon />} size="small" width="206px" onClick={onSignInClick}>
        {t('login.signIntoAccount')}
      </Button>
    </AccountLogInWrapper>
  );
};

export default AccountLogIn;
