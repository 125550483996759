import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Nullable } from 'tsdef';
import {
  Flex,
  LoginIcon,
  colors,
  Logo,
  Link,
  getMinWidthMediaQuery,
  useMediaQuery,
  Avatar,
  Button,
  BellIcon,
  Caption,
  useClickOutside,
  BottomSheet,
} from '@beauty/beauty-market-ui';
import { excludeMenuRouteList, getSelectedLanguage } from '../../constants';
import { getFullDate } from '../../helpers/appointments';
import { changeNotificationStatus } from '../../helpers/notifications';
import { cleanUpAfterLogout } from '../../helpers/profile';
import { useGetNotifications } from '../../hooks/useGetNotifications';
import { useGetProfile } from '../../hooks/useGetProfile';
import { useScrollY } from '../../page/HomePage/hooks/useScrollPosition';
import LogoutSidebar from '../../page/LogoutSidebar/LogoutSidebar';
import { RouterUrl } from '../../routes/routes';
import { useAppSelector } from '../../store/hooks';
import { headerState } from '../../store/redux-slices/headerSlice';
import { selectOrganisation, setShowSearch } from '../../store/redux-slices/organisationSlice';
import { selectUser, updateNotificationStatus } from '../../store/redux-slices/userSlice';
import { Language } from '../../types';
import { NotificationStatus } from '../../types/notifications';
import { MobileMenu } from '../MobileMenu/MobileMenu';
import { NotificationsPopup } from '../NotificationsPopup/NotificationsPopup';
import { RatingSidebar, RatingSidebarProps } from '../RatingSidebar/RatingSidebar';
import { Search } from '../Search';
import MenuClient from './MenuClient';
import {
  HeaderWrapper,
  MenuWrapper,
  HeaderGrid,
  BellWrapper,
  CaptionWrapper,
  ButtonsWrapper,
  StyledWrapper,
} from './style';

const Header = () => {
  const [isLoginPage, setLoginPage] = useState<boolean>(false);
  const [isHomePage, setIsHomePage] = useState<boolean>(true);
  const [isForBusinessPage, setIsForBusinessPage] = useState<boolean>(false);
  const [isLogoutOpen, setLogoutOpen] = useState(false);
  const [isNotificationsPopup, setNotificationsPopup] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState<Nullable<RatingSidebarProps>>(null);
  const { t } = useTranslation();
  const language = getSelectedLanguage();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const bellRef = useRef<Element | null>(null);

  const isShowMobileMenu = !excludeMenuRouteList.find(route => location.pathname.includes(route));
  const rtl = language === Language.Hebrew;

  const posY = useScrollY();
  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);

  const { isLogin, user, activeNotificationId } = useAppSelector(selectUser);
  const { isOnTop, isShowMenu } = useAppSelector(headerState);
  const { isShowSearch: isSearch } = useAppSelector(selectOrganisation);

  useGetProfile();
  useGetNotifications(user.userId);

  const { notifications } = useAppSelector(selectUser);

  const counter = notifications?.new?.filter(notification => !notification.isRead).length;

  useEffect(() => {
    const tempLocation = location.pathname.match('login');
    setLoginPage(!!tempLocation);
    setIsHomePage(location.pathname === RouterUrl.Homepage);
    setIsForBusinessPage(location.pathname === RouterUrl.ForBusiness);
    !location.pathname.includes('organisation') && dispatch(setShowSearch(true));
  }, [location]);

  const isShowSearch = useMemo(
    () => !isHomePage && !isLoginPage && !isMobile && !isForBusinessPage && isSearch,
    [isHomePage, isLoginPage, isMobile, isSearch],
  );

  const currentNotification = useMemo(
    () =>
      notifications
        ? [...notifications.new, ...notifications.archive]?.find(item => item.id === activeNotificationId)
        : undefined,
    [notifications, activeNotificationId],
  );

  const loginPress = () => {
    !isLogin ? navigate(RouterUrl.LoginIntro, { state: { type: 'login' } }) : navigate(RouterUrl.LogOut);
  };

  const signUpPress = () => {
    !isLogin ? navigate(RouterUrl.LoginIntro, { state: { type: 'signup' } }) : navigate(RouterUrl.LogOut);
  };

  const goHome = () => {
    navigate(RouterUrl.Homepage);
  };

  const handleClick = (navigateTo: string) => {
    isLogin ? navigate(`${navigateTo}/${user.userId}`) : navigate(RouterUrl.Login);
  };

  const onLogout = () => {
    cleanUpAfterLogout(dispatch, navigate);
    setLogoutOpen(false);
  };

  const onLogoutCancel = () => {
    setLogoutOpen(false);
  };

  const loginAnotherUser = () => {
    navigate(RouterUrl.Login);
    setLogoutOpen(false);
  };

  const notificationsPopupProps = {
    isOpen: isNotificationsPopup,
    title: t('notifications.title'),
    items: notifications?.new ?? [],
    setRead: async (index: number) => {
      if (!notifications.new[index].isRead) {
        const response = await changeNotificationStatus(notifications.new[index].id);
        response?.status === NotificationStatus.VIEWED && dispatch(updateNotificationStatus({ isNew: true, index }));
      }
    },
    setNotificationsPopup,
  };

  useClickOutside(bellRef, () => {
    setNotificationsPopup(false);
  });

  useEffect(() => {
    if (currentNotification) {
      const { isRead, status, type, id, ...details } = currentNotification;
      const { appointmentId, time, organization, orgAvatar, specialist, specAvatar, specialization, fullAddress } =
        details;
      setAppointmentDetails({
        appointmentId,
        date: getFullDate(time, t, language),
        address: {
          orgName: organization,
          info: fullAddress,
          url: orgAvatar,
        },
        specialist: specialist
          ? {
              orgSpecId: '',
              name: specialist,
              surname: '',
              avatarUrl: specAvatar,
              specialization,
              rating: null,
            }
          : undefined,
      });
    }
  }, [currentNotification]);

  useEffect(() => {
    !activeNotificationId && setAppointmentDetails(null);
  }, [activeNotificationId]);

  return isShowMenu ? (
    <HeaderWrapper isOnTop={isOnTop || (!isHomePage && !isForBusinessPage) || (isMobile && posY > 5)}>
      <Flex width="100%" maxWidth="1060px" margin="auto" flexDirection="column" position="relative">
        <HeaderGrid rtl={rtl}>
          <Logo
            gridArea="logo"
            justifyContent={rtl ? 'end' : 'start'}
            labelColor={colors.black.standard}
            logoColor={colors.blue.standard}
            isHideLabel={isMobile}
            monochrome
            onClick={goHome}
            mt="4px"
          />
          <Flex
            gridArea="search"
            justifyItems="center"
            align-items="stretch"
            width="100%"
            justifyContent="center"
            pl="15px"
            pr="15px"
          >
            {isShowSearch && <Search />}
          </Flex>
          <ButtonsWrapper gridArea="navigationMenu" rtl={rtl}>
            {!isForBusinessPage && (
              <Link size="s" design="black" href={RouterUrl.ForBusiness} target="_blank">
                {t('header.forBusiness')}
              </Link>
            )}
            {isLogin && (
              <BellWrapper ref={!isMobile ? bellRef : null}>
                <BellIcon onClick={() => setNotificationsPopup(pr => !pr)} />
                {!!counter && (
                  <CaptionWrapper onClick={() => setNotificationsPopup(pr => !pr)}>
                    <Caption large lowline>
                      {counter}
                    </Caption>
                  </CaptionWrapper>
                )}
                {isMobile ? (
                  <BottomSheet
                    ref={isMobile ? bellRef : null}
                    isOpen={isNotificationsPopup}
                    content={<NotificationsPopup {...notificationsPopupProps} />}
                    onClose={() => setNotificationsPopup(false)}
                    detent="content-height"
                  />
                ) : (
                  <NotificationsPopup {...notificationsPopupProps} />
                )}
              </BellWrapper>
            )}

            {isLogin && !isLoginPage && !isMobile && !isForBusinessPage && <MenuClient setLogoutOpen={setLogoutOpen} />}
            {!isLogin && !isLoginPage && (
              <StyledWrapper rtl={rtl}>
                <Link
                  mr="16px"
                  design="blue"
                  icon={!isMobile && <LoginIcon fill="none" />}
                  size="s"
                  onClick={loginPress}
                >
                  {t('header.logIn')}
                </Link>
                <Button size="extraSmall" onClick={signUpPress}>
                  {t('header.signUp')}
                </Button>
              </StyledWrapper>
            )}
            {isLogin && <Avatar size="xs" name={user.name} url={user.avatarUrl} />}
          </ButtonsWrapper>
        </HeaderGrid>
        {isMobile && isShowMobileMenu && (
          <MenuWrapper>
            <MobileMenu
              onSearchClick={() => navigate(RouterUrl.Search)}
              onCalendarClick={() => handleClick(RouterUrl.ClientAppointments)}
              onHeartClick={() => handleClick(RouterUrl.ClientFavourites)}
              onProfileClick={() => handleClick(RouterUrl.ClientProfile)}
            />
          </MenuWrapper>
        )}
        {activeNotificationId && appointmentDetails && <RatingSidebar {...appointmentDetails} />}
        <LogoutSidebar
          loginAnotherUser={loginAnotherUser}
          isOpen={isLogoutOpen}
          onLogout={onLogout}
          onLogoutCancel={onLogoutCancel}
        />
      </Flex>
    </HeaderWrapper>
  ) : null;
};

export default Header;
